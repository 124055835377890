import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomControlsModule } from '@identic/controls';
import { ApplicationDataAccessModule } from '@application/data-access';
import { ApplicationSelectorComponent } from './selector.component';

export const COMPONENTS = [ApplicationSelectorComponent];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule,
    ApplicationDataAccessModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ApplicationSelectorModule {}

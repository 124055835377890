import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { APPLICATION_KEY_FIELD, Application } from './application.model';
import { APPLICATION_FEATURE_KEY, ApplicationPartialState, ApplicationState, ApplicationActions } from './application.state';

@Injectable()
export class ApplicationFacade extends BaseFacade<Application> {
  constructor(private store: Store<ApplicationPartialState>) {
    super(store, ApplicationActions, new EntityStateSelector<ApplicationState>(APPLICATION_FEATURE_KEY, APPLICATION_KEY_FIELD));
  }
}
// console.log(`%cApplicationFacade::ngOnInit`, 'background:yellow');

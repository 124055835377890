// import { Client } from '@client/data-access';
import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { Institution, INSTITUTION_KEY_FIELD } from './institution.model';

export const INSTITUTION_FEATURE_KEY = 'Institution';

export type InstitutionState = BaseEntityState<Institution>;
export const InstitutionActions = new BaseEntityActions<Institution>(INSTITUTION_FEATURE_KEY, INSTITUTION_KEY_FIELD);

export interface InstitutionPartialState {
  readonly [INSTITUTION_FEATURE_KEY]: InstitutionState;
}

export const institutionInitialState: InstitutionState = new EmptyEntityState;

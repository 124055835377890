import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';

import { ConfigService, safeValue } from '@identic/core';
import { BaseService } from '@identic/api';
import { DynamicQuestionUtilsService } from '@identic/questions';
import { Application, APPLICATION_DISPLAY_FIELD } from './+state/application.model';

const defaultApi = 'BULAapplication';

@Injectable({providedIn: 'root'})
export class ApplicationService extends BaseService<Application> {

  constructor(
    private dynamicQuestionsService: DynamicQuestionUtilsService,
    http: HttpClient,
    config: ConfigService,
    injector: Injector,
    // @Optional() @Inject(PopupDialogErrorDisplayService) errorDisplayService: IHttpErrorDisplayService,
  ) {
    super(injector, http, `${config.environment.API_BASE_URL}/${safeValue(config, 'environment.Api.Application', defaultApi)}`, APPLICATION_DISPLAY_FIELD);
  }

  public getAllTest(): Observable<any> {
console.log(`%cApplicationService::ngOnInit`, 'background:yellow');
    return this.http.get<any>('https://localhost:44371/v1.0/BULAapplication/faculty/token/5c535560-1f9d-44d3-a2f7-49bddf866027')
    .pipe(
      map(response => {
  console.log(`%cApplicationRenewAccessComponent::`, 'background:green', response);
        return response?.body;
      }),
      catchError(error => {
  console.log(`%cApplicationRenewAccessComponent::CatchError`, 'background:red', error);
  return throwError(() => error);
      })
    )
  }

  public uploadToSiNet = (uploadFile: any, dryRun = false): Observable<any> => {
    const formData: FormData = new FormData();
    formData.append('file', uploadFile, uploadFile.name);
    return super.post(formData, `/import/sinet?commit_records=${!dryRun}`);
  }

  public renewAccessToken = (token: string): Observable<any> => super.get(`/faculty/token/${token}/refresh`);
  public approveForFaculty = (data: { id: string, commentModel: any, token: string }): Observable<Application> => super.update(data.commentModel, `/faculty/token/${data.token}/approve`);
  public rejectForFaculty = (data: { id: string, commentModel: any, token: string }): Observable<Application> => super.update(data.commentModel, `/faculty/token/${data.token}/reject`);
  public updateForFaculty = (token:string, data: any): Observable<Application> => super.update(data, `/faculty/token/${token}`);

  public approve = (data: { id: string, commentModel: any}): Observable<Application> => super.update(data.commentModel, `/${data.id}/approve`);
  public reject = (data: { id: string, commentModel: any}): Observable<Application> => super.update(data.commentModel, `/${data.id}/reject`);
  public revert = (data: { id: string, commentModel: any}): Observable<Application> => super.update(data.commentModel, `/${data.id}/revert`);
  public delete = (data: { id: string, commentModel: any}): Observable<any> => super.request('delete', `/${data.id}/withComment`, { body: data.commentModel });

  public archive = (data: { id: string, commentModel: any}): Observable<Application> => super.post(data.commentModel, `/${data.id}/archive`);
  public resendEmail = (data: { id: string, commentModel: any}): Observable<any> => super.post(data.commentModel, `/${data.id}/email`);

  // public downloadArchive = (id: string): Observable<any> => super.customUpdate(null, `/download/archive/file/${id}`, { observe: 'response', responseType: 'arraybuffer' });
  // public downloadAsCSV = (data: string[] | string, studentFriendlyComments: boolean = true): Observable<any> => this.downloadAsFile('csv', data, studentFriendlyComments);
  // public downloadAsPDF = (data: string[] | string, studentFriendlyComments: boolean = true): Observable<any> => this.downloadAsFile('pdf', data, studentFriendlyComments);

  public downloadAsFile = (fileType: string, data: string[] | string, studentFriendlyComments: boolean = true): Observable<any> => {
    const uriSuffix = fileType + (studentFriendlyComments ? '/student' : '');

    // options.responseType = ResponseContentType.ArrayBuffer;
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');

    if (Array.isArray(data) && (data.length === 1)) {
      data = data[0];
    }

    return (typeof(data) === 'string')
          ? super.getFile(`/${data}/${uriSuffix}`)  // Single id
          : super.getFile(`/report/${uriSuffix}`, { application_ids: data });  // Multiple ids. NOTE: Uses HttpPut NOT HttpGet
  }

  // ----------------------------------
  // Questions
  public MigratePreV1Questions(oldVersion: any, newVersion: any): any {
    const questionTemplate = newVersion;

    if (typeof oldVersion === 'string') {
      oldVersion = JSON.parse(oldVersion);
    }

    if (oldVersion.version === questionTemplate.version) { return oldVersion; }

    const keyValuePairs: any = {};
    const keyOptionsPairs: any = {};
    this.iteratePreV1QuestionsModel(oldVersion, (item: any) => {
      keyValuePairs[item.id] = item.value;
      if (item.options) {
        keyOptionsPairs[item.id] = item.options;
      }
    });


    // Set values
    this.dynamicQuestionsService.setFieldValues(keyValuePairs, questionTemplate);

    // Set dropdown options
    this.setFieldOptions(keyOptionsPairs, questionTemplate);

    // Set other non-value fields that started as tokens
    questionTemplate.questions.forEach((step: any) => {
      step.legend = oldVersion[step.id].header;
    });

    const emailSettings = oldVersion.contact.questions.find((q: any) => q.id === 'email_address');
    this.dynamicQuestionsService.iterateQuestionsModel(questionTemplate, (item: any) => {
      if (item.id === 'email_address') {
        item.hint = emailSettings.hint;
        item.validators = emailSettings.validators;
        item.errorMessages = emailSettings.errorMessages;
      }
    });

    return questionTemplate;
  }

  iteratePreV1QuestionsModel(model: any, actionFn: (p: any) => any): void {
    if (!model) { return; }

    const iterateModelFn = function (items: any[]): boolean {
      items.forEach(item => actionFn(item));
      return true;
    };

    Object.keys(model).forEach((step: string) => {
      iterateModelFn(model[step].questions);
    });
  }

  setFieldOptions(data: any, model: any): void {
    this.dynamicQuestionsService.iterateQuestionsModel(model, (item: any) => {
      let keysFound = 0;
      Object.keys(data).forEach(key => {
        if (item.id === key) {
          item.options = data[key];
          ++keysFound;
        }
      });
      return Object.keys(data).length > keysFound;    // Found all so stop iterating
    });
  }

}
// console.log(`%cApplicationService::ngOnInit`, 'background:yellow');

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ApplicationEffects } from './+state/application.effects';
import { ApplicationFacade } from './+state/application.facade';
import { ApplicationReducer } from './+state/application.reducer';
import { APPLICATION_FEATURE_KEY, applicationInitialState as initialState } from './+state/application.state';
import { ApplicationJsonService } from './application-json.service';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForApplication = StoreModule.forFeature(APPLICATION_FEATURE_KEY, ApplicationReducer, { initialState });
export const effectsModuleForApplication = EffectsModule.forFeature([ApplicationEffects]);

@NgModule({
  imports: [
    CommonModule,
    storeModuleForApplication,
    effectsModuleForApplication
  ],
  providers: [
    ApplicationFacade,
    ApplicationJsonService
  ]
})
export class ApplicationDataAccessModule {}

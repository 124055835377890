import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseSelectorComponent } from '@identic/core';
import { APPLICATION_DISPLAY_FIELD, Application, ApplicationFacade } from '@application/data-access';

export const APPLICATION_SELECTOR_PATH ='application-select';
export const APPLICATION_SELECTOR_ACTION = {
  actionName: 'application-select-event',
  title: 'Change Application',
  icon: 'domain',
  colour: 'primary'
};

@Component({
  selector: 'application-selector',
  template: `
<div [class.container]="(selected$ | async) === undefined">
  <div [class.row]="(selected$ | async) === undefined">
    <div [class.col-6]="(selected$ | async) === undefined">
      <custom-selector *ngIf="loaded$ | async" class="ms-3"
        label="{{(selected$ | async) === undefined ? 'Choose' : 'Change'}} Application..."
        [displayField]="itemDisplayField"
        [materialUI]="materialUI"
        [noPrompt]="noPrompt"
        [prompt]="prompt"
        noOptionsText="Sorry, but there are no Application items to choose from"
        [showChoices]="showChoices"
        [selectOptions]="(list$ | async)!"
        [selectedItem]="selected$ | async"
        (change)="onChange($event)">
      </custom-selector>
    </div>
  </div>
</div>
  <!-- <div class="container">
  <custom-selector *ngIf="(loaded$ | async) && ((list$ |async)!.length > 1) && showChoices"
    prompt="<div class='row pt-1'>
              <div class='col'>
                <h3>Application selector</h3>
              </div>
            </div>
            <div class='row'>
              <div class='col'>
                <p>Please select an Application</p>
              </div>
            </div>"
    label="Choose Application..."
    displayField="name"
    [materialUI]="materialUI"
    [noPrompt]="noPrompt"
    [selectOptions]="(list$ | async)!"
    [selectedItem]="selected$ | async"
    (change)="onChange($event)">
  </custom-selector>
</div> -->
`,
  styles: []
})
export class ApplicationSelectorComponent extends BaseSelectorComponent<Application> {
  @Input() materialUI = true;
  @Input() noPrompt = true;
  @Input() prompt = '';

  itemDisplayField = APPLICATION_DISPLAY_FIELD;
  itemFacadeSelectFn = (selectedItem: Application) => this.facade.select(selectedItem.id!);

  constructor(route: ActivatedRoute, router: Router, private facade: ApplicationFacade) {
    super(route, router, facade);
  }

}
// console.log(`%cApplicationSelectorContainer::ngOnInit`, 'background:yellow');

import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService, safeValue } from '@identic/core';
import { BaseService } from '@identic/api';
import { Institution, INSTITUTION_DISPLAY_FIELD } from './+state/institution.model';

const defaultApi = 'institution';

@Injectable({providedIn: 'root'})
export class InstitutionService extends BaseService<Institution> {

  constructor(
    http: HttpClient,
    config: ConfigService,
    injector: Injector,
    // @Optional() @Inject(PopupDialogErrorDisplayService) errorDisplayService: IHttpErrorDisplayService,
  ) {
    super(injector, http, `${config.environment.API_BASE_URL}/${safeValue(config, 'environment.Api.Institution', defaultApi)}`, INSTITUTION_DISPLAY_FIELD);
  }

}

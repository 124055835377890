// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// This file is replaced during build by using the `fileReplacements` array.
// `ng build -c staging-sms` replaces `environment.ts` with `sms/environment.staging.ts`.
// `ng build -c staging` replaces `environment.ts` with `environment.staging.ts`. *** Identic version
// The list of file replacements can be found in `angular.json`.
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --staging` replaces `environment.ts` with `environment.staging.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ANON_TEMPLATE_API_URL: 'https://test.school-management-system.app.uq.edu.au/API/Template/v1.0',
  VIRTUAL_DIR: '/',
  SSO_SERVER_URL: 'https://identity.school-management-system.app.uq.edu.au',
  API_BASE_URL: 'https://test.school-management-system.app.uq.edu.au/API/BULA/v1.0',
  IDSVR4_API_SCOPE: 'BULA.API.Test',
  IDSVR4_API_CLIENT: 'BULA.UI.Test',
  AUTH_LOG_WARNING: true,
  // AUTH_LOG_DEBUG: true,
  menuClickClearsPageState: true,

  // STUDENT_APPLY_QUESTIONNAIRE_TEMPLATE: 'student-apply.questionnaire.json',  // Path to questionnaire template
  // STUDENT_APPLY_TOKENS: 'student-apply.tokens.json',  // Path to questionnaire tokens
};

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BaseSelectorGuard } from '@identic/core';
import { Institution, InstitutionFacade } from '@institution/data-access';
import { INSTITUTION_SELECTOR_PATH } from './selector.component';

@Injectable({
  providedIn: 'root'
})
export class InstitutionGuard extends BaseSelectorGuard<Institution> {
  constructor(
    private router: Router,
    facade: InstitutionFacade,
  ) {
    super(facade.selected$);
  }

  navigateToSelector = (redirectUrl: string | undefined): void => {
    this.router.navigate([INSTITUTION_SELECTOR_PATH], { queryParams: { redirectUrl } });
  }
}
// console.log(`%cInstitutionGuard::ngOnInit`, 'background:yellow');

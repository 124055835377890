import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseSelectorComponent, safeValue } from '@identic/core';
import { INSTITUTION_DISPLAY_FIELD, Institution, InstitutionFacade } from '@institution/data-access';

export const INSTITUTION_SELECTOR_PATH ='institution-select';
export const INSTITUTION_SELECTOR_ACTION = {
  actionName: 'institution-select-event',
  title: 'Change Institution',
  icon: 'domain',
  colour: 'primary'
};

@Component({
  selector: 'institution-selector',
  template: `
<ng-container *ngIf="textOnly; else imageTemplate">
  <div [class.container]="(selected$ | async) === undefined">
    <div [class.row]="(selected$ | async) === undefined">
      <div [class.col-6]="(selected$ | async) === undefined">
        <custom-selector *ngIf="loaded$ | async" class="ms-3"
          label="{{(selected$ | async) === undefined ? 'Choose' : 'Change'}} Institution..."
          [displayField]="itemDisplayField"
          [materialUI]="materialUI"
          [noPrompt]="noPrompt"
          [prompt]="prompt"
          noOptionsText="Sorry, but there are no Institution items to choose from"
          [showChoices]="showChoices"
          [selectOptions]="(list$ | async)!"
          [selectedItem]="dontSelectFacade ? null : (selected$ | async)"
          (change)="onChange($event)">
        </custom-selector>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #imageTemplate>
  <div class="container">
    <div class="row">
      <h1 class="col-12 d-flex justify-content-center">{{label}}</h1>
    </div>
    <mat-spinner *ngIf="!(loaded$ | async)"></mat-spinner>
    <mat-button-toggle-group *ngIf="loaded$ | async" class="row" (change)="onChange($event.value)" aria-label="Home University">
      <mat-button-toggle *ngFor="let uni of (list$ |async)" class="col" [value]="uni"><img class="img-fluid" src="assets/images/{{uni?.name}}-logo.png"></mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</ng-template>
`
})
export class InstitutionSelectorComponent extends BaseSelectorComponent<Institution> {
  @Input() materialUI = true;
  @Input() noPrompt = false;
  @Input() prompt = '';
  @Input() label = 'Choose an institution...';
  @Input() textOnly = false;
  @Input() dontSelectFacade: boolean = false;

  itemDisplayField = INSTITUTION_DISPLAY_FIELD;
  itemFacadeSelectFn = (selectedItem: Institution) => (this.dontSelectFacade ? null : this.facade.select(safeValue(selectedItem, 'id')));

  constructor(route: ActivatedRoute, router: Router, private facade: InstitutionFacade) {
    super(route, router, facade);
  }

}
// console.log(`%cInstitutionSelectorContainer::ngOnInit`, 'background:yellow');

// import { Client } from '@client/data-access';
import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { Application, APPLICATION_KEY_FIELD } from './application.model';

export const APPLICATION_FEATURE_KEY = 'Application';

export type ApplicationState = BaseEntityState<Application>;
export const ApplicationActions = new BaseEntityActions<Application>(APPLICATION_FEATURE_KEY, APPLICATION_KEY_FIELD);

export interface ApplicationPartialState {
  readonly [APPLICATION_FEATURE_KEY]: ApplicationState;
}

export const applicationInitialState: ApplicationState = new EmptyEntityState;

import { ApplicationStatus } from '@application-status/data-access';
import { Citizenship } from '@citizenship/data-access';
import { CourseOffering } from '@course-offering/data-access';
import { Credit } from '@credit/data-access';
import { Faculty } from '@faculty/data-access';
import { Institution } from '@institution/data-access';
import { ProgramLevel } from '@program-level/data-access';
import { Sponsor } from '@sponsor/data-access';
import { Visa } from '@visa/data-access';

export const APPLICATION_KEY_FIELD = 'id';
export const APPLICATION_DISPLAY_FIELD = 'email_address';

export interface Application {
  id?: string;
  address?: string;
  citizenship?: Citizenship;  // citizenship_id?: string;
  country?: string;
  course_offering?: CourseOffering;  // course_offering_id?: string;
  credit_type?: Credit;  // credit_type_id?: string;
  current_enrolled_program?: string;
  current_status?: ApplicationStatus;
  date_of_birth?: Date;
  destination_institution?: Institution;  // destination_institution_id?: string;
  destination_student_id?: string;
  email_address?: string;
  fee_help?: boolean;
  fee_paying?: boolean;
  fee_status_id?: string;
  // fee_status?: FeeStatus;
  first_name?: string;
  gender?: string;
  home_faculty?: Faculty;
  home_institution?: Institution;  // home_institution_id?: string;
  home_student_id?: string;
  home_uni_sponsor?: Sponsor;
  home_uni_sponsor_id?: string;
  last_archived?: Date;
  last_name?: string;
  last_updated?: Date;
  middle_name?: string;
  phone?: string;
  postcode?: string;
  preferred_name?: string;
  program_code?: string;
  program_level?: ProgramLevel;  // program_level_id?: string;
  program_title?: string;
  questions_model?: string; // questions and answers the applicant entered
  state?: string;
  studied_at_destination?: boolean;
  submission_date?: Date;
  suburb?: string;
  title?: string;
  tracking_code?: number;
  visa_type?: Visa;  // visa_type_id?: string;
}

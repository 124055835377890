import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'bula-home',
  template: `
  <mat-card class="container">
    <mat-card-content>
      <mat-spinner *ngIf="!templateIsReady"></mat-spinner>
      <template-display [application]="(routeData$ | async)!['application']" templateName="HomePage" (visible)="templateIsReady = true"></template-display>
    </mat-card-content>

    <mat-card-actions>
      <button *ngIf="templateIsReady" mat-raised-button color="primary" title="Apply" (click)="goToApply()">
        <mat-icon>edit</mat-icon>Apply Now
      </button>
    </mat-card-actions>

  </mat-card>
  `
})
export class HomeComponent {
  routeData$ = this.route.data;
  templateIsReady: boolean = false;

  constructor(public route: ActivatedRoute, private router: Router) { }

  goToApply(): void {
    this.router.navigateByUrl('apply');
  }
}
// console.log(`%c HomeComponent::ngOnInit`, 'background:yellow');

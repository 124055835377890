import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { InstitutionEffects } from './+state/institution.effects';
import { InstitutionFacade } from './+state/institution.facade';
import { InstitutionReducer } from './+state/institution.reducer';
import { INSTITUTION_FEATURE_KEY, institutionInitialState as initialState } from './+state/institution.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForInstitution = StoreModule.forFeature(INSTITUTION_FEATURE_KEY, InstitutionReducer, { initialState });
export const effectsModuleForInstitution = EffectsModule.forFeature([InstitutionEffects]);

@NgModule({
  imports: [
    CommonModule,
    storeModuleForInstitution,
    effectsModuleForInstitution
  ],
  providers: [InstitutionFacade]
})
export class InstitutionDataAccessModule {}

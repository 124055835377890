import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from '@identic/core';
import { BaseJsonAssetLoadService } from '@identic/api';

const DEFAULT_STUDENT_APPLY_TOKENS   = 'student-apply.tokens.json';  // Path to questionnaire tokens
const DEFAULT_STUDENT_APPLY_QUESTIONNAIRE_TEMPLATE  = 'student-apply.questionnaire.json';  // Path to questionnaire

@Injectable({providedIn: 'root'})
export class ApplicationJsonService extends BaseJsonAssetLoadService<any> {

  constructor(http: HttpClient,
    private config: ConfigService,
    injector: Injector,
    // @Optional() @Inject(PopupDialogErrorDisplayService) errorDisplayService: IHttpErrorDisplayService,
  ) {
    super(injector, http, config.environment.ASSET_ROOT);
  }

  getQuestionnaireTemplate(): Observable<any> {
    return this.getAssetFile(this.config.environment.STUDENT_APPLY_QUESTIONNAIRE_TEMPLATE?? DEFAULT_STUDENT_APPLY_QUESTIONNAIRE_TEMPLATE);
  }

  getQuestionnaireTokens(): Observable<any> {
    return this.getAssetFile(this.config.environment.STUDENT_APPLY_TOKENS?? DEFAULT_STUDENT_APPLY_TOKENS);
  }

}
// console.log(`%cApplicationJsonService::ngOnInit`, 'background:yellow');

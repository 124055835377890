export const INSTITUTION_KEY_FIELD = 'id';
export const INSTITUTION_DISPLAY_FIELD = 'name';

export interface Institution {
  id?: string;
  contact_email?: string;
  contact_name?: string;
  full_name?: string;
  name?: string;
  role?: string;
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { INSTITUTION_KEY_FIELD, Institution } from './institution.model';
import { INSTITUTION_FEATURE_KEY, InstitutionPartialState, InstitutionState, InstitutionActions } from './institution.state';

@Injectable()
export class InstitutionFacade extends BaseFacade<Institution> {
  constructor(private store: Store<InstitutionPartialState>) {
    super(store, InstitutionActions, new EntityStateSelector<InstitutionState>(INSTITUTION_FEATURE_KEY, INSTITUTION_KEY_FIELD));
  }
}
// console.log(`%cInstitutionFacade::ngOnInit`, 'background:yellow');

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomControlsModule } from '@identic/controls';
import { InstitutionDataAccessModule } from '@institution/data-access';
import { InstitutionSelectorComponent } from './selector.component';

export const COMPONENTS = [InstitutionSelectorComponent];

@NgModule({
  imports: [
    CommonModule,
    CustomControlsModule,
    InstitutionDataAccessModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class InstitutionSelectorModule {}

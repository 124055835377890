import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { InstitutionPartialState, InstitutionActions } from './institution.state';
import { InstitutionService } from '../institution.service';

@Injectable()
export class  InstitutionEffects {

  
  loadAllEntities$ = createEffect(() => this.dataPersistence.fetch(InstitutionActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => InstitutionActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => InstitutionActions.ActionError(action + error)
  }));

  
  createEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(InstitutionActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(map((response: any) => InstitutionActions.Added({ payload: response }))),
    onError: (action: any, error) => InstitutionActions.ActionError(action + error)
  }));

  
  updateEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(InstitutionActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => InstitutionActions.Updated({ payload: response }))),
    onError: (action: any, error) => InstitutionActions.ActionError(action + error)
  }));

  
  deleteEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(InstitutionActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => InstitutionActions.Deleted({ payload: response }))),
    onError: (action: any, error) => InstitutionActions.ActionError(action + error)
  }));

  constructor(
    private dataPersistence: DataPersistence<InstitutionPartialState>,
    private apiService: InstitutionService,
  ) {}
}

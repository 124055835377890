import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthRoutes } from '@identic/auth';
import { NotFoundPageComponent } from '@identic/controls';
import { RedirectGuard } from '@identic/core';
import { appRoutes } from './app.routing';

// AOT compiler: Function calls are not supported in decorators
export const routerModuleForRoot = RouterModule.forRoot([
      ...AuthRoutes,
      ...appRoutes,
      { path: '**', component: NotFoundPageComponent, canActivate: [RedirectGuard] },
    ], {
      useHash: false,
      enableTracing: false, // <-- debugging purposes only
      // preloadingStrategy: SelectivePreloadingStrategyService,
    });

@NgModule({
  imports: [routerModuleForRoot],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { ApplicationPartialState, ApplicationActions } from './application.state';
import { ApplicationService } from '../application.service';

@Injectable()
export class  ApplicationEffects {


  loadAllEntities$ = createEffect(() => this.dataPersistence.fetch(ApplicationActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => ApplicationActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => ApplicationActions.ActionError(action + error)
  }));

  createEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(ApplicationActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(map((response: any) => ApplicationActions.Added({ payload: response }))),
    onError: (action: any, error) => ApplicationActions.ActionError(action + error)
  }));


  updateEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(ApplicationActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => ApplicationActions.Updated({ payload: response }))),
    onError: (action: any, error) => ApplicationActions.ActionError(action + error)
  }));


  deleteEntity$ = createEffect(() => this.dataPersistence.pessimisticUpdate(ApplicationActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => ApplicationActions.Deleted({ payload: response }))),
    onError: (action: any, error) => ApplicationActions.ActionError(action + error)
  }));

  constructor(
    private dataPersistence: DataPersistence<ApplicationPartialState>,
    private apiService: ApplicationService,
  ) {}
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BaseSelectorGuard } from '@identic/core';
import { Application, ApplicationFacade } from '@application/data-access';
import { APPLICATION_SELECTOR_PATH } from './selector.component';

@Injectable({
  providedIn: 'root'
})
export class ApplicationGuard extends BaseSelectorGuard<Application> {
  constructor(
    private router: Router,
    facade: ApplicationFacade,
  ) {
    super(facade.selected$);
  }

  navigateToSelector = (redirectUrl: string | undefined): void => {
    // No application selected so redirects to select-a-application page.
    this.router.navigate([APPLICATION_SELECTOR_PATH], { queryParams: { redirectUrl } });
  }
}
// console.log(`%cApplicationGuard::ngOnInit`, 'background:yellow');

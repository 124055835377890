import { Routes } from '@angular/router';

import { MenuItems } from '@identic/layout';
import { INSTITUTION_SELECTOR_PATH, InstitutionSelectorComponent } from '@institution/selector';
// import { AuthGuard } from '@identic/auth';
import { IDENTIC_APP_SELECTOR_PATH, IdenticAppSelectorComponent } from '@identic/identic-app';

import { HomeComponent } from './home.component';

// Page options are here and referenced twice below in Routes & menuItems
// E.G. appointments: { text: 'Appointments', icon: 'people', path: 'appointments', routerLink: '/appointments/list', roles: 'SMS.Admin&&(Unit.EDU||Unit.SLC)' },

const canChangeInstitutionRoles = 'SMS.Admin||BULA.Superuser';

const Page = {
  home: { text: 'Home', icon: 'home', path: 'home', routerLink: 'home', application: 'BULA', loggedInOnly: true },

  studentApply: { text: 'Apply', icon: 'school', path: 'apply', routerLink: '/apply' },

  // --- SuperUser Only ---
  logs: { text: 'Logs', icon: 'list', path: 'logs', routerLink: 'logs/list', roles: 'SMS.Admin||BULA.Superuser' },
  pageTemplates: { text: 'Page Editor', icon: 'web', path: 'page-template', routerLink: 'page-template/list', roles: 'SMS.Admin' },
  schedulerAdmin: { text: 'Scheduler Admin', icon: 'schedule', path: 'scheduler', routerLink: 'scheduler/list', roles: 'SMS.Admin' },
  schedulerUser: { text: 'Scheduler', icon: 'schedule', path: 'scheduler', routerLink: 'scheduler/user', roles: 'SMS.Admin||BULA.Superuser' },
  schedulerLogs: { text: 'Scheduler Logs', icon: 'library_books', path: 'scheduler', routerLink: 'scheduler/logs', roles: 'SMS.Admin||BULA.Superuser' },
  // selectInstitutionAdmin: { text: 'Change Institution', icon: 'swap_horizontal_circle', path: INSTITUTION_SELECTOR_PATH, routerLink: INSTITUTION_SELECTOR_PATH, roles: 'SMS.Admin||BULA.Superuser' },

  // --- Table Maintenance ---
  citizenship: { text: 'Citizenship', icon: 'settings', path: 'citizenship', routerLink: '/citizenship/list', roles: 'SMS.Admin||BULA.Superuser' },
  credit: { text: 'Credit', icon: 'settings', path: 'credit', routerLink: '/credit/list', roles: 'SMS.Admin||BULA.Superuser' },
  faculty: { text: 'Faculty', icon: 'settings', path: 'faculty', routerLink: '/faculty/list', roles: 'SMS.Admin||BULA.Superuser' },
  feeStatus: { text: 'Fee Status', icon: 'settings', path: 'fee-status', routerLink: '/fee-status/list', roles: 'SMS.Admin||BULA.Superuser' },
  institution: { text: 'Institution', icon: 'settings', path: 'institution', routerLink: '/institution/list', roles: 'SMS.Admin||BULA.Superuser' },
  institutionSemester: { text: 'Institution Semester', icon: 'settings', path: 'institution-semester', routerLink: '/institution-semester/list', roles: 'SMS.Admin||BULA.Superuser' },
  language: { text: 'Language', icon: 'settings', path: 'language', routerLink: '/language/list', roles: 'SMS.Admin||BULA.Superuser' },
  programLevel: { text: 'Program Level', icon: 'settings', path: 'program-level', routerLink: '/program-level/list', roles: 'SMS.Admin||BULA.Superuser' },
  semester: { text: 'Semester', icon: 'settings', path: 'semester', routerLink: '/semester/list', roles: 'SMS.Admin||BULA.Superuser' },
  sponsor: { text: 'Sponsor', icon: 'settings', path: 'sponsor', routerLink: '/sponsor/list', roles: 'SMS.Admin||BULA.Superuser' },
  visa: { text: 'Visa', icon: 'settings', path: 'visa', routerLink: '/visa/list', roles: 'SMS.Admin||BULA.Superuser' },

  // --- All Uni Admins ---
  course: { text: 'Course', icon: 'settings', path: 'course', routerLink: '/course/list', roles: 'SMS.Admin||BULA.Superuser||BULA.Admin', canChangeInstitutionRoles },
  courseOffering: { text: 'Course Offering', icon: 'settings', path: 'course-offering', routerLink: '/course-offering/list', roles: 'SMS.Admin||BULA.Superuser||BULA.Admin', canChangeInstitutionRoles },
  courseOfferingApproval: { text: 'Course Offering Approval', icon: 'settings', path: 'course-offering/approval', routerLink: '/course-offering/approval', roles: 'SMS.Admin||BULA.Superuser||BULA.Admin', canChangeInstitutionRoles },
  applicationOpeningBypass: { text: 'Opening Bypass', icon: 'schedule', path: 'application-opening-bypass', routerLink: '/application-opening-bypass/list', roles: 'SMS.Admin||BULA.Superuser||BULA.Admin', canChangeInstitutionRoles },
  applications: { text: 'Applications', icon: 'school', path: 'application', routerLink: '/application/list', roles: 'SMS.Admin||BULA.Superuser||BULA.Admin', canChangeInstitutionRoles },
  applicationsAdmin: { text: 'Applications', icon: 'school', path: 'application-list', routerLink: '/application-list/list', roles: 'SMS.Admin||BULA.Superuser||BULA.Admin', canChangeInstitutionRoles },
  applicationsFinance: { text: 'Applications Finance', icon: 'attach_money', path: 'applications-list/finance', routerLink: '/applications-list/finance/list', roles: 'SMS.Admin||BULA.Superuser||BULA.Finance', canChangeInstitutionRoles },
  applicationSelect: { text: 'Change Application', icon: 'swap_horizontal_circle', path: IDENTIC_APP_SELECTOR_PATH, routerLink: IDENTIC_APP_SELECTOR_PATH, roles: 'SMS.Admin||BULA.Superuser||BULA.Admin', canChangeInstitutionRoles },
  selectInstitution: { text: 'Change Institution', icon: 'swap_horizontal_circle', path: INSTITUTION_SELECTOR_PATH, routerLink: INSTITUTION_SELECTOR_PATH, roles: 'SMS.Admin||BULA.Superuser||BULA.Admin', canChangeInstitutionRoles },
  emailTemplates: { text: 'Email Templates', icon: 'email', path: 'email-template', routerLink: 'email-template/list', roles: 'SMS.Admin||BULA.Superuser||BULA.Admin||Email.Template.Editor', canChangeInstitutionRoles },

  // --- UQ Admin Only ---
  sinetImport: { text: 'Sinet Import', icon: 'settings', path: 'sinet-import', routerLink: '/application/sinet-import', roles: 'SMS.Admin||BULA.Superuser||(BULA.Admin&&BULA.UQ)' },

  // --- Unused ---
  // questionsMaster: { text: 'Questions Master', icon: 'settings', path: 'questions-master', routerLink: '/questions-master/list', roles: 'SMS.Admin||BULA.Superuser' },
  // setting: { text: 'Setting', icon: 'settings', path: 'setting', routerLink: '/setting/list', roles: 'SMS.Admin||BULA.Superuser' },
  // token: { text: 'Token', icon: 'settings', path: 'token', routerLink: '/token/list', roles: 'SMS.Admin||BULA.Superuser' },

};

export const appRoutes: Routes = [
  { path: Page.home.path, redirectTo: '', pathMatch: 'full' },
  { path: '', component: HomeComponent, data: Page.home }, //, canActivate: [AuthGuard], data: { roles: 'admin' }  // rf: https://medium.com/@ryanchenkie_40935/angular-authentication-using-route-guards-bf7a4ca13ae3

  { path: Page.applicationSelect.path, component: IdenticAppSelectorComponent, data: Page.applicationSelect }, //canActivate: [AuthGuard] },
  { path: Page.applications.path, loadChildren: () => import('@application/editor').then(m => m.ApplicationEditorModule), data: Page.applicationsAdmin, /*canActivate: [AuthGuard]*/},
  { path: Page.applicationOpeningBypass.path, loadChildren: () => import('@application-opening-bypass/editor').then(m => m.ApplicationOpeningBypassEditorModule), data: Page.applicationOpeningBypass, /*canActivate: [AuthGuard]*/},
  { path: Page.applicationsAdmin.path, loadChildren: () => import('@application-list/editor').then(m => m.ApplicationListEditorModule), data: Page.applicationsAdmin, /*canActivate: [AuthGuard]*/},
  { path: Page.applicationsFinance.path, loadChildren: () => import('@application-list/editor-finance').then(m => m.ApplicationListFinanceEditorModule), data: Page.applicationsFinance, /*canActivate: [AuthGuard]*/},
  { path: Page.citizenship.path, loadChildren: () => import('@citizenship/editor').then(m => m.CitizenshipEditorModule), data: Page.citizenship, /*canActivate: [AuthGuard]*/},
  { path: Page.course.path, loadChildren: () => import('@course/editor').then(m => m.CourseEditorModule), data: Page.course, /*canActivate: [AuthGuard]*/},
  { path: Page.courseOffering.path, loadChildren: () => import('@course-offering/editor').then(m => m.CourseOfferingEditorModule), data: Page.course, /*canActivate: [AuthGuard]*/},
  { path: Page.courseOfferingApproval.path, loadChildren: () => import('@course-offering/approval').then(m => m.CourseOfferingApprovalModule), data: Page.course, /*canActivate: [AuthGuard]*/},
  { path: Page.credit.path, loadChildren: () => import('@credit/editor').then(m => m.CreditEditorModule), data: Page.credit, /*canActivate: [AuthGuard]*/},
  { path: Page.emailTemplates.path, loadChildren: () => import('@identic/email').then(m => m.EmailTemplateModule), data: Page.emailTemplates, /*canActivate: [AuthGuard]*/ },
  { path: Page.faculty.path, loadChildren: () => import('@faculty/editor').then(m => m.FacultyEditorModule), data: Page.faculty, /*canActivate: [AuthGuard]*/},
  { path: Page.feeStatus.path, loadChildren: () => import('@fee-status/editor').then(m => m.FeeStatusEditorModule), data: Page.feeStatus, /*canActivate: [AuthGuard]*/},
  { path: Page.institution.path, loadChildren: () => import('@institution/editor').then(m => m.InstitutionEditorModule), data: Page.institution, /*canActivate: [AuthGuard]*/},
  { path: Page.institutionSemester.path, loadChildren: () => import('@institution-semester/editor').then(m => m.InstitutionSemesterEditorModule), data: Page.institutionSemester, /*canActivate: [AuthGuard]*/},
  { path: Page.language.path, loadChildren: () => import('@language/editor').then(m => m.LanguageEditorModule), data: Page.language, /*canActivate: [AuthGuard]*/},
  { path: Page.logs.path, loadChildren: () => import('@identic/log-to-db').then(m => m.LogToDbModule), data: Page.logs },
  { path: Page.pageTemplates.path, loadChildren: () => import('@identic/templates').then(m => m.PageTemplateModule), data: Page.pageTemplates},
  { path: Page.programLevel.path, loadChildren: () => import('@program-level/editor').then(m => m.ProgramLevelEditorModule), data: Page.programLevel, /*canActivate: [AuthGuard]*/},
  { path: Page.schedulerAdmin.path, loadChildren: () => import('@identic/scheduler').then(m => m.SchedulerModule), data: Page.schedulerAdmin, /*canActivate: [AuthGuard]*/},
  { path: Page.selectInstitution.path, component: InstitutionSelectorComponent, data: Page.selectInstitution}, //canActivate: [AuthGuard] },
  { path: Page.semester.path, loadChildren: () => import('@semester/editor').then(m => m.SemesterEditorModule), data: Page.semester, /*canActivate: [AuthGuard]*/},
  { path: Page.sponsor.path, loadChildren: () => import('@sponsor/editor').then(m => m.SponsorEditorModule), data: Page.sponsor, /*canActivate: [AuthGuard]*/},
  { path: Page.studentApply.path, loadChildren: () => import('@application/apply').then(m => m.ApplicationApplyModule), data: Page.studentApply, /*canActivate: [AuthGuard]*/},
  { path: Page.visa.path, loadChildren: () => import('@visa/editor').then(m => m.VisaEditorModule), data: Page.visa, /*canActivate: [AuthGuard]*/},

  // --- Unused ---
  // { path: Page.questionsMaster.path, loadChildren: () => import('@questions-master/editor').then(m => m.QuestionsMasterEditorModule), data: Page.questionsMaster, /*canActivate: [AuthGuard]*/},
  // { path: Page.setting.path, loadChildren: () => import('@setting/editor').then(m => m.SettingEditorModule), data: Page.setting, /*canActivate: [AuthGuard]*/},
  // { path: Page.sinetImport.path, loadChildren: () => import('@sinet-import').then(m => m.SinetImportModule), data: Page.sinetImport, /*canActivate: [AuthGuard]*/},
  // { path: Page.token.path, loadChildren: () => import('@token/editor').then(m => m.TokenEditorModule), data: Page.token, /*canActivate: [AuthGuard]*/},

  // // Not Found page with external link redirection
  // { path: 'not-found', component: NotFoundPageComponent, canActivate: [RedirectGuard] },
  // { path: '**', redirectTo: 'not-found' }
];

export const menuItems: MenuItems = [
  Page.home,

  Page.studentApply,

  Page.applicationsAdmin,
  Page.applicationsFinance,
  Page.applicationOpeningBypass,

  Page.logs,

  { text: 'Maintenance', icon: 'build', roles: 'SMS.Admin||BULA.Superuser||BULA.Admin', children: [
    // Page.selectInstitutionAdmin,
    Page.courseOffering,
    Page.courseOfferingApproval,
    Page.sinetImport,
    Page.pageTemplates,
    Page.emailTemplates,
    Page.schedulerAdmin,
    // Page.schedulerLogs,
    Page.schedulerUser,
    { text: 'Tables', icon: 'settings', roles: 'SMS.Admin||BULA.Superuser', children: [
      Page.citizenship,
      Page.course,
      Page.credit,
      Page.faculty,
      Page.feeStatus,
      Page.institution,
      Page.institutionSemester,
      Page.language,
      Page.programLevel,
      Page.semester,
      Page.sponsor,
      Page.visa,
      // Page.questionsMaster,
      // Page.setting,
      // Page.token,
    ]},
  ]},
];
